import {createContext, ReactNode, useContext} from "react"
import {isNotNullOrUndefined} from "~/utils/isNotNullOrUndefined"

const IsLoggedInContext = createContext<{
  isLoggedIn: boolean
  userId: null
} | {
  isLoggedIn: true
  userId: string
} | null>(null);

export const UserContextProvider = ({
  userId,
  children
}: {
  userId: string | null,
  children?: ReactNode
}) => {
  return (
    <IsLoggedInContext.Provider
      value={isNotNullOrUndefined(userId) ? {
        isLoggedIn: true,
        userId
      }: {
        isLoggedIn: false,
        userId: null
      }}
    >
      {children}
    </IsLoggedInContext.Provider>
  )
}
export const useUserContext = () => {
  const context = useContext(IsLoggedInContext)
  if (!context) throw new Error("You can only use IsLoggedInContext within it's provider")
  return context
}